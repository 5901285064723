import BANKS from '@pretto/config/banks.json'
import { RowBanks } from '@pretto/website/src/templates/home/rows/rowBanks/components/RowBanks'

export type RowBanksContainerProps = {
  banks: { label: string }[]
  title: string
  description: string
  isColored: boolean
}

export const RowBanksContainer = ({ banks, title, description, isColored }: RowBanksContainerProps) => {
  const banksShown = banks.map(({ label }) => label)

  const rowBanksProps = {
    description,
    isColored,
    logos: banksShown.reduce((previous, label) => {
      const bank = BANKS.find(({ short_label }) => short_label === label)

      if (!bank) {
        return previous
      }

      return [...previous, bank.logo.regular]
    }, [] as string[]),
    title,
  }

  return <RowBanks {...rowBanksProps} />
}
